import { type BillAddr, QbCustomer } from '@/dto/quickbooks';
import { InvoissError } from '@/helpers/invoissError';
import { SimpleLineItem } from '@/types/schema';
import { pipe } from 'fp-ts/function';
import { isEmpty, isNil, isNumber, keyBy } from 'lodash-es';
import { omitBy } from 'lodash/fp';

export const calculateUnitPrice = ( lineItem: SimpleLineItem ) => {
	let modifierPrice = 0;
	if ( isEmpty( lineItem.metadata ) ) return lineItem.price;
	if ( isEmpty( lineItem.modifierGroups ) ) return lineItem.price;
	const modifiers = keyBy( lineItem.modifierGroups.map( ( g ) => g.modifiers ).flat(), 'externalId' );
	for ( const externalId in lineItem.metadata ) {
		const qty = lineItem.metadata[ externalId ];
		if ( !qty || !isNumber( qty ) ) continue;
		modifierPrice += ( modifiers[ externalId ]?.value || 0 ) * qty;
	}
	return lineItem.price + modifierPrice;
};

export const convertQbAddress = ( address?: BillAddr ) => {
	if ( !address?.Line1 ) return null;
	return pipe( address,
		( addr ) => ( {
			line1     : addr.Line1,
			line2     : addr.Line2,
			city      : addr.City || '-',
			state     : addr.CountrySubDivisionCode || '-',
			country   : addr.Country || 'US',
			postalCode: addr.PostalCode,
			lat       : addr.Lat ? Number( addr.Lat ) : undefined,
			lng       : addr.Long ? Number( addr.Long ) : undefined,
		} ),
		omitBy( isNil ),
	);
};

// TOBE removed, no need to maintain an intermediate contract
export const parseQbItem = ( qbCompanyId: string, item: any ) => ( {
	name        : item.Name,
	description : item.Description ?? null,
	id          : `${qbCompanyId}-${item.Id}-quickbooks`,
	parentRef   : item.ParentRef,
	unitPrice   : parseFloat( item.UnitPrice ),
	purchaseCost: parseFloat( item.PurchaseCost ),
	qty         : item.QtyOnHand ? parseInt( item.QtyOnHand ) : 0,
	taxable     : item.Taxable,
	createdAt   : item.MetaData.CreateTime,
	updatedAt   : item.MetaData.LastUpdatedTime,
	syncToken   : item.SyncToken,
} );

//TOBE Removed, no need to maintain an intermediate contract with `convertQbClientToValidator`
export const parseQbClient = ( qbCompanyId: string, customer: QbCustomer ) => ( {
	name     : customer.CompanyName || customer.FullyQualifiedName || customer.DisplayName,
	id       : `${qbCompanyId}-${customer.Id}-quickbooks`,
	email    : customer.PrimaryEmailAddr?.Address,
	contact  : customer.GivenName ? `${customer.GivenName || ''} ${customer.FamilyName || ''}` : '',
	phone    : customer.PrimaryPhone?.FreeFormNumber,
	cell     : customer.Mobile?.FreeFormNumber,
	taxable  : customer.Taxable,
	createdAt: customer.MetaData.CreateTime,
	updatedAt: customer.MetaData.LastUpdatedTime,
	syncToken: customer.SyncToken,
	addresses: [
		!!customer.BillAddr && {
			line1     : customer.BillAddr.Line1,
			city      : customer.BillAddr.City,
			state     : customer.BillAddr.CountrySubDivisionCode,
			country   : 'US',
			postalCode: customer.BillAddr.PostalCode,
			type      : 'BILLING',
		},
		!!customer.ShipAddr && {
			line1     : customer.ShipAddr.Line1,
			city      : customer.ShipAddr.City,
			state     : customer.ShipAddr.CountrySubDivisionCode,
			country   : 'US',
			postalCode: customer.ShipAddr.PostalCode,
			type      : 'SHIPPING',
		},
	].filter( Boolean ),
} );

const UNKNOWN_ERROR = 'An error occurred while syncing with QuickBooks';

export const handleQbError = ( error: any ) => {
	const response = error.response?.data;
	if ( !response ) return new InvoissError( {
		message   : error.message || UNKNOWN_ERROR,
		statusCode: 500,
		error,
	} );
	
	const err = response.Fault?.Error || response.fault?.error;
	const firstError = err?.[ 0 ];
	const code = firstError?.Code || firstError?.code;
	let message: string = firstError?.Detail || firstError?.Message || firstError.message;
	
	switch ( code ) {
		case '3001':
			message = 'The sync queue has reached the limit, please sync later';
			break;
	}
	
	if ( message ) message = `${message} (quickbooks.com)`;
	
	return new InvoissError( {
		message   : message || UNKNOWN_ERROR,
		statusCode: message ? 400 : 500,
		error     : err,
	} );
};

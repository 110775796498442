import { NextApiResponse } from 'next';
import { z } from 'zod';

export const getErrorDetails = ( e?: {
	message?: string,
	networkError?: any,
	graphQLErrors?: any,
	response?: any
} ) => e
	? e.networkError?.result?.errors || e.graphQLErrors || e.response?.data || e.message || e
	: null;

export class InvoissError extends Error {
	constructor( { message, statusCode, error }: { message?: string, statusCode: number, error?: any } ) {
		if ( error instanceof InvoissError ) return error;
		if ( error instanceof z.ZodError ) {
			return new InvoissError( {
				message   : 'Invalid request parameters',
				statusCode: 400,
				error     : error.errors,
			} );
		}
		super( message );
		this.message = message || error?.message || 'Unknown Error';
		this.statusCode = statusCode;
		this.error = getErrorDetails( error );
	}
	
	message: string;
	statusCode: number;
	error: any;
	
	sendLegacyError( res: NextApiResponse ) {
		res.status( this.statusCode ).json( { message: this.message, statusCode: this.statusCode, error: this.error } );
	}
	
	json() {
		return {
			message   : this.message,
			statusCode: this.statusCode,
			error     : this.error,
		};
	}
}

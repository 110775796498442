import { BillAddr } from '@/dto/quickbooks';
import { ClientValidator, Maybe } from '../types/schema';

export const inferQbPrivateNote = ( { po, metadata }: { po?: Maybe<string>, metadata?: { privateNote?: string } } ) =>
	[ po ? `PO: ${po}` : '',
	  metadata?.privateNote ? `PrivateNote: ${metadata.privateNote}` : '' ]
		.filter( Boolean )
		.join( '\n' );

export const convertQbAddressToValidator = ( address: BillAddr ) => {
	if ( !address.Line1 ) {
		return null;
	}
	
	const line2 = ( address.City || address.Country
		? [ address.Line2 ]
		: [ address.Line2, address.Line3, address.Line4 ] )
		.filter( Boolean ).join( ', ' );
	
	return {
		line1     : address.Line1,
		line2,
		city      : address.City || '-',
		state     : address?.CountrySubDivisionCode || '-',
		postalCode: address.PostalCode || undefined,
		country   : address.Country || '-',
	};
};

export function convertQbClientToValidator( data ): ClientValidator {
	let modifyEmail;
	if ( data.email?.includes( ',' ) ) {
		modifyEmail = data.email.split( ',' )[ 0 ];
	}
	
	const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
	
	return {
		externalValue: data.id,
		name         : data.name?.length ? data.name : '',
		contact      : data.contact?.length ? data.contact : '-',
		email        : data.email?.length && emailRegex.test( modifyEmail || data.email )
			? modifyEmail || data.email
			: null,
		phone        : data.phone?.length ? data.phone : '',
		createdAt    : data.createdAt,
		updatedAt    : data.updatedAt,
		metadata     : { qbSyncToken: data.syncToken, exemptFromTax: data ? !data.taxable : false },
		addresses    : data.addresses?.map( ( address ) => {
			if ( address.line1 ) {
				return {
					line1     : address.line1,
					line2     : address?.line2 || '-',
					state     : address?.state || '-',
					city      : address?.city || '-',
					postalCode: address?.postalCode || '-',
					country   : address?.country || 'USA',
					type      : address.type,
				};
			}
		} ).filter( Boolean ) || [],
	} as ClientValidator;
}
